import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Toolset,
  Project,
  TitleContent,
  ExternalLink,
  WorkedWith,
} from "../components/parts"

const StaticWebsites = () => (
  <Layout title="Static websites">
    <Title heading="Static websites" sub="Landing pages" />
    <Project
      heading="Agya ventures"
      sub="VC focussed on real estate tech."
      style={{ marginBottom: 0 }}
    />
    <div className="section-bottom">
      <img
        src="/images/static-websites/agya.png"
        alt="agya"
        className="bordered"
      />
    </div>
    <TitleContent title="Visit">
      <ExternalLink href="https://agyaventures.com/" label="Website" />
    </TitleContent>
    <Toolset tools={["gatsbyjs", "illustrator"]} />
    <WorkedWith people={["kunal"]} />
    <Project
      heading="The HouseMonk"
      sub="VC focussed on real estate tech."
      style={{ marginBottom: 0 }}
    />
    <div className="section-bottom">
      <img
        src="/images/static-websites/housemonk.png"
        alt="housemonk"
        className="bordered"
      />
    </div>
    <TitleContent title="Visit">
      <ExternalLink href="https://thehousemonk.com/" label="Website" />
    </TitleContent>
    <Toolset tools={["illustrator"]} />
    <Project
      heading="WaveFront Venture Partners"
      sub="A new type of Venture builder"
      style={{ marginBottom: 0 }}
    />
    <div className="section-bottom">
      <img
        src="/images/static-websites/wavefront.png"
        alt="wavefront"
        className="bordered"
      />
    </div>
    <TitleContent title="Visit">
      <ExternalLink href="https://wavefrontvp.com/" label="Website" />
    </TitleContent>
    <Toolset tools={["reactjs"]} />
    <WorkedWith people={["eric"]} />
    <Project
      heading="Amimed Cura"
      sub="For everyday nutritional needs"
      style={{ marginBottom: 0 }}
    />
    <div className="section-bottom">
      <img
        src="/images/static-websites/amimed.png"
        alt="amimed"
        className="bordered"
      />
    </div>
    <TitleContent title="Visit">
      <ExternalLink href="https://www.amimedcura.com/home" label="Website" />
    </TitleContent>
    <Toolset tools={["reactjs"]} />
  </Layout>
)

export default StaticWebsites
